<template>
<CHeader fixed with-subheader light>

    <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
    <CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('toggleSidebarDesktop')" />
    <CHeaderBrand class="mx-auto d-lg-none">
        <img src="img/logo_sig.png" class="img-fluid mt-0 mb-0" width="200px">
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
        <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem> -->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
        <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
        <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
        <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
        <ContentDropdown />
    </CHeaderNav>
    <CSubheader class="px-3">
        <CBreadcrumbRouter class="border-0 mb-0" />
        <!-- {{this.$route.matched[2].meta}} -->
    </CSubheader>
</CHeader>
</template>

<script>
import ContentDropdown from './ContentDropdown'

export default {
    name: 'ContentHeader',
    components: {
        ContentDropdown
    },
    data() {
        return {
            collapsed: false,
            datoSession: {
                logo: JSON.parse(localStorage.settings).logo
            },

        }
    },
    created() {
        let primario = JSON.parse(localStorage.settings).primaryColor;
        let secundario = JSON.parse(localStorage.settings).secondaryColor;
        this.setThemeColors(primario, secundario);
    },
    methods: {
        setThemeColors(primario, secundario) {
            let style = document.documentElement.style;
            style.setProperty("--bg-primary-color", primario);
            style.setProperty("--bg-secondary-color", secundario);
            //style.setProperty("--bg-accent-color", accent);
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.matched[1].name
        }
    },
    mounted() {}
}
</script>

<style lang="scss">
.bg-primario {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

.modal-primario .modal-header {
    color: var(--bg-secondary-color) !important;
    background-color: var(--bg-primary-color) !important;
}

.card-accent-primario {
    border-top: 2px solid var(--bg-primary-color) !important;
}

.dropdown-active {
    background: rgba(0, 0, 0, 0.25) !important;
    // filter: brightness(85%);
    border-radius: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--bg-primary-color) !important;
}

.bg-nav {
    background-color: var(--bg-primary-color) !important;
}

.table-custom thead th {
    color: var(--bg-secondary-color) !important;
    background: var(--bg-primary-color) !important;
    border-bottom: 0px;
}

/* ------------------------------- */

.bootstrap-datetimepicker-widget table td span.active,
.bootstrap-datetimepicker-widget table td i.active {
    background-color: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td i:hover {
    background-color: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

/* ------------------------------- */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bg-secondary-color) !important;
    background-color: var(--bg-primary-color) !important;
}

.nav-link {
    color: var(--bg-primary-color) !important;
}

.nav-link:hover {
    color: var(--bg-primary-color) !important;
}

/* ------------------------------- */

.vs__dropdown-option:hover {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

.vs__dropdown-option--highlight {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

/* ------------------------------- */

a {
    text-decoration: none;
    background-color: transparent;
    color: var(--bg-primary-color);
}

a:hover {
    background-color: transparent;
    color: var(--bg-primary-color) !important;
}

/* ------------------------------- */

.btn-custom {
    color: var(--bg-primary-color) !important;
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    //F9F9F9
}

.btn-custom:hover {
    color: var(--bg-secondary-color) !important;
    background-color: var(--bg-primary-color) !important;
    border-color: var(--bg-primary-color) !important;
}

.btn-custom:focus,
.btn-custom.focus {
    box-shadow: 0 0 0 0px var(--bg-primary-color) !important;
}

.btn-custom.disabled,
.btn-custom:disabled {
    background-color: #F9F9F9;
    border-color: #F9F9F9;
}

.btn-custom:active,
.btn-custom.active,
.show>.btn-custom.dropdown-toggle {
    background-color: var(--bg-primary-color) !important;
    background-image: none;
    border-color: var(--bg-primary-color) !important;
}

.dropdown-toggle:focus {
    box-shadow: 0 0 0 0;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    color: var(--bg-secondary-color) !important;
}

/* ------------------------------- */
.btn-datepicker {
    color: var(--bg-primary-color);
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:hover {
    color: var(--bg-primary-color);
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:focus,
.btn-datepicker.focus {
    box-shadow: 0 0 0 0px var(--bg-secondary-color) !important;
}

.btn-datepicker.disabled,
.btn-datepicker:disabled {
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:active,
.btn-datepicker.active,
.show>.btn-datepicker.dropdown-toggle {
    background-color: var(--bg-secondary-color) !important;
    background-image: none;
    border-color: var(--bg-secondary-color) !important;
}

.input-custom {
    width: 45px;
    height: 30px;
    color: transparent;
    text-shadow: 0 0 0 var(--bg-primary-color) !important;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    text-align: center;
    font-weight: bolder;
}

/* ------------------------------- */

.icon-custom .ico {
    color: #cfcfcf !important;
}

.icon-custom:hover .ico {
    color: var(--bg-primary-color) !important;
}

/*--------- pagination ----------*/

.page-item.active .page-link {
    background: var(--bg-primary-color) !important;
    border-color: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

.page-link {
    color: var(--bg-primary-color) !important;
}

/* ------------------------------- */

.bg-accordion,
.bg-accordion:focus {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
    padding: 5px;
    outline: 0px !important;
}

.bg-accordion-transparent,
.bg-accordion-transparent:focus {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
    filter: opacity(70%);
    padding: 5px;
    outline: 0px !important;
}

.drop-custom {
    color: var(--bg-secondary-color) !important;
}

.drop-custom:hover {
    color: var(--bg-secondary-color) !important;
    filter: brightness(80%);
}

.dropdown-toggle::after {
    display: none
}

.show-arrow-down-dropdown-button>.dropdown-toggle-split::after {
    display: inline-block;
}

.dropdown-icon {
    font-size: 0.8rem
}

.dropdown-icon:hover {
    font-size: 0.83rem
}

/* .navbar.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
} */
</style><style lang="scss">
.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        // background: rgba(255, 255, 255, 0);
        // background: var(--bg-primary-color) !important;
        color: var(--bg-secondary-color) !important;
        font-size: 0.875rem;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        max-width: 250px;
    }

    &[x-placement^="top"] {

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 10px;

        .tooltip-arrow {
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }
}

.c-sidebar {
    color: var(--bg-secondary-color) !important;
    background: var(--bg-primary-color) !important;
}

@media (hover: hover),
not all {

    .c-sidebar .c-sidebar-nav-link:hover,
    .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        color: var(--bg-secondary-color) !important;
        background: rgba(0, 0, 0, 0.20) !important;
    }
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bg-secondary-color) !important;
    background-color: var(--bg-primary-color) !important;
}
</style>
