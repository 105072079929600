<template>
<CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
        <CHeaderNavLink>
            <div class="c-avatar">
                <img src="img/perfil.png" class="c-avatar-img " />
            </div>
        </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>{{$store.state.user.nombre || 'Usuario'}}</strong>
    </CDropdownHeader>
    <!-- <CDropdownItem>
      <CIcon name="cil-bell"/> Actualizaciones
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Mensajes
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tareas
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comentarios
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Ajustes</strong>
    </CDropdownHeader> -->
    <CDropdownItem :to="{name: 'Perfil'}">
        <b-icon icon="person-fill"></b-icon> Perfil
    </CDropdownItem>
    <!-- <CDropdownItem>
      <b-icon icon="gear-fill"></b-icon>Ajustes
    </CDropdownItem> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-dollar" /> Pagos
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> proyectos
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <CDropdownDivider />
    <!-- <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Bloquear cuenta
    </CDropdownItem> -->

    <CDropdownItem @click="logout()">
        <b-icon icon="lock-fill"></b-icon> Cerrar sesión
    </CDropdownItem>
</CDropdown>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'ContentDropdown',
  data() {
    return {
      itemsCount: 42,
      usuario: "Hola",
    }
  },
  methods: {
    logout() {
      let me = this;
      me.$swal.fire({
        title: '¿Estas seguro?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Cerrar Sesión',
        cancelButtonColor: '#1276e2',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          // firebase.auth().signOut().then(() => {
          //     this.$router.push({
          //         name: "Login Cliente",
          //         params: {
          //             rsCliente: JSON.parse(localStorage.settings).path
          //         }
          //     })
          //     localStorage.clear();
          //     // this.$router.push({
          //     //     name: 'Login'
          //     // })
          // })

          me.$router.push({
            name: "Login Cliente",
            params: {
                rsCliente: JSON.parse(localStorage.settings).path
            }
          });

          localStorage.clear();
          me.$store.commit('SET_USER_DATA', {usernrodoc: null,passnrodoc: null});

          
          
          // this.$router.push({
          //     name: 'Login'
          // })
            
        }
      })
    },

  },
  mounted() {
      // let datosLocal = JSON.parse(localStorage.getItem('usuario'));
      // this.username = datosLocal.username;

  }
}
</script>

<style scoped>
.b-icon {
    margin-right: 0.3rem;
}
</style>
