export default [{
    _name: 'CSidebarNav',
    _children: [
        // {
        //     _name: 'CSidebarNavTitle',
        //     _children: ['Control']
        // },
        {
            _name: 'CSidebarNavItem',
            name: 'Panel de control',
            to: '/panel-de-control',
            icon: 'cil-speedometer',
            // badge: {
            //     color: 'primary',
            //     text: 'NEW'
            // }
        },
        // {
        //     _name: 'CSidebarNavTitle',
        //     _children: ['CRM']
        // },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Proyectos',
        //     to: '/proyectos',
        //     icon: 'cil-command',
        // },
        {
            _name: 'CSidebarNavItem',
            name: 'Usuarios',
            to: '/usuarios',
            // icon: 'ico fas fa-hiking fa-3x pb-1',
            icon: 'cil-user',
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Información del Grupo',
            route: '/cargar-informacion',
            icon: 'cil-cloud-upload',
            items: [
                {
                    name: 'Itinerarios',
                    to: '/cargar-informacion/itinerarios'
                },
                {
                    name: 'Lista de Pasajeros',
                    to: '/cargar-informacion/lista-pasajeros'
                },
                {
                    name: 'Reporte',
                    to: '/cargar-informacion/reporte'
                },
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Calidad',
            route: '/calidad',
            icon: 'cil-badge',
            items: [
                {
                    name: 'Equipos',
                    to: '/calidad/almacen-equipos'
                },
                {
                    name: 'Check list guías y personal',
                    to: '/calidad/guias-y-personal'
                },
                // {
                //     name: 'Check list alimentos',
                //     to: '/calidad/alimentos-e-insumos'
                // },
                // {
                //     name: 'Check list infraestructura individual',
                //     to: ''
                // },
                // {
                //     name: 'Check list infraestructura grupal',
                //     to: ''
                // },
                // {
                //     name: 'Check list verificación de guias',
                //     to: '/calidad/verificacion-guias'
                // },
                // {
                //     name: 'Trip evaluation form',
                //     to: '/calidad/trip-evaluation-form'
                // },
                // {
                //     name: 'Salida no conforme',
                //     to: ''
                // },
                // {
                //     name: 'Quejas y reclamos',
                //     to: '/calidad/quejas-y-reclamos'
                // },
                // {
                //     name: 'Servicio no conforme',
                //     to: '/calidad/servicio-no-conforme'
                // },
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Seguridad',
            route: '/seguridad',
            icon: 'cil-shield-alt',
            items: [
                {
                    name: 'Limpieza e Higiene',
                    to: '/seguridad/limpieza-higiene'
                },
                {
                    name: 'Inspección botiquin',
                    to: '/seguridad/inspeccion-botiquin'
                },
                {
                    name: 'Accidentes e Incidentes',
                    to: '/seguridad/accidente-incidentes'
                },
                {
                    name: 'Alimentos',
                    to: '/seguridad/alimentos'
                },
                
                // {
                //     name: 'Condiciones de salubridad de campamento',
                //     to: '/seguridad/condiciones-de-salubridad-de-campamento'
                // },
                // {
                //     name: 'Ficha de sintomatología',
                //     to: '/seguridad/ficha-sintomatologia'
                // },
                // {
                //     name: 'Control de entrega epps',
                //     to: '/seguridad/control-de-entrega-epps'
                // },
                // {
                //     name: 'Charla informativa',
                //     to: '/seguridad/charla-informativa'

                // },
                // {
                //     name: 'Check list protocolos sitio arqueologico',
                //     to: '/seguridad/protocolos-sitio-arqueologico'
                // },
                // {
                //     name: 'Registro de incidentes',
                //     to: '/seguridad/registro-incidente'
                // },
                
                // {
                //     name: 'Seguridad Ruta',
                //     to: '/seguridad/seguridad-ruta'
                // },
                {
                    name: 'Trekking',
                    to: '/seguridad/trekking'
                },
                // {
                //     name: 'epps',
                //     to: '/seguridad/epps'
                // }               // {
                //     name: 'Registro de extintor',
                //     to: '/seguridad/registro-de-extintor'
                // },
                
                
            ]
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Calificaciones',
            to: '/calificaciones',
            // icon: 'ico fas fa-hiking fa-3x pb-1',
            icon: 'cib-apache-spark',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Comentarios',
            to: '/comentarios',
            // icon: 'ico fas fa-hiking fa-3x pb-1',
            icon: 'cil-comment-bubble',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Quejas de Usuario',
            to: '/quejas-usuario',
            // icon: 'ico fas fa-hiking fa-3x pb-1',
            icon: 'cil-mood-very-bad',
        },
        // {
        //     _name: 'CSidebarNavDropdown',
        //     name: 'Configuración información',
        //     route: '/configuracion',
        //     icon: 'cil-shield-alt',
        //     items: [
        //         {
        //             name: 'Configuración información',
        //             to: '/configuracion/configuracion-informacion'
        //         },
        //         // {
        //         //     name: 'Gestión de configuración información app',
        //         //     to: '/configuracion/gestion-configuracion-informacion'

        //         // },
             
             
                
        //     ]
        // },
       
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Administrar comentarios',
        //     to: '/master/documentos-comerciales',
        //     icon: 'cil-settings',
        // },

        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Download CoreUI',
        //     href: 'http://coreui.io/vue/',
        //     icon: { name: 'cil-cloud-download', class: 'text-white' },
        //     _class: 'bg-success text-white',
        //     target: '_blank'
        // },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Try CoreUI PRO',
        //     href: 'http://coreui.io/pro/vue/',
        //     icon: { name: 'cil-layers', class: 'text-white' },
        //     _class: 'bg-danger text-white',
        //     target: '_blank'
        // }
        // {
        //     _name: 'CSidebarNavTitle',
        //     _children: ['Soporte']
        // },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Incidencias de software',
        //     to: '/master/incidencias-de-software',
        //     icon: 'cil-warning',
        // },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Servicios',
        //     to: '/master/servicios',
        //     icon: 'cil-cog',
        // },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Perfiles',
        //     to: '/master/perfiles',
        //     icon: 'cil-fingerprint',
        // },
    ]
}]